import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import boxIcon from "../../../assets/images/box-open.svg";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";
import { Table, Form } from "react-bootstrap";
import SearchIcon from "../../../assets/images/Search.svg";
import UserContext from "../../../context/userContext";
import BackArrow from "../../../assets/images/back-arrow.svg";
import invoice from "../../../assets/images/file-invoice-dollar.svg";
import editIcon from "../../../assets/images/edit.svg";
import { useSnackbar } from "notistack";
import moment from "moment";
import Service from "../../../shared/_services/inventory.service";
import AddIcon from "../../../assets/images/add-white.svg";

import TrialImg from "../../../assets/images/trial-img.svg";

import "./companyDetails.css";

const CompanyDetails = () => {
  const user = useContext(UserContext);
  const [search, setSearch] = useState("");
  const [filteredWorkerData, setFilteredWorkerData] = useState([]);
  const [filteredInventoryData, setFilteredInventoryData] = useState([]);
  const [filteredHybridData, setFilteredHybridData] = useState([]);
  const [filteredFieldData, setFilteredFieldData] = useState([]);
  const accessToken = user?.id;
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [invoiceData, setInvoiceData] = useState();
  const [status, setStatus] = useState();
  const navigate = useNavigate();

  const [companyData, setCompanyData] = useState({
    CompanyName: "",
    CompanyCode: "",
    databaseName: "",
    url: "",
    AuthID: "",
    databaseURL: "",
    databasePassword: "",
    databaseUser: "",
    SAPURL: "",
    SAPClientID: "",
    SAPRefreshToken: "",
    SAPURL2: "",
    SAPClientSecret: "",
    productType: "",
    PricePerWorker: 0,
    PricePerInventoryStaff: 0,
    PricePerHybridUser: 0,
    PricePerField : 0,
    isTrial: false,
    trialDays: 0,
    JoiningDate: "",
    Disabled: "",
  });

  // const checkCompanyPaymentStatus = (cp) => {
  //   console.log("cp",cp)
  //   if (cp?.StripePaymentCharged === true) {
  //     return "Active";
  //   } else {
  //     return "Inactive";
  //   }
  // };

  const checkCompanyPaymentStatus = (cp) => {
    console.log("cp", cp);
  
    const { StripePaymentCharged, TrialDays , JoiningDate } = cp;
    const currentDate = new Date();
  
    // Check if the trial period is still active
    if (JoiningDate) {
      const joiningDate = new Date(JoiningDate);
      const trialEndDate = new Date(joiningDate);
      trialEndDate.setDate(trialEndDate.getDate() + TrialDays);
  
      // Return "Active" if within the trial period
      if (currentDate <= trialEndDate) {
        return "Active";
      }
    }
  
    // Return "Active" if payment has been charged
    if (StripePaymentCharged === true) {
      return "Active";
    }
  
    // If neither condition is true, return "Inactive"
    return "Inactive";
  };
  
  

  useEffect(() => {
    Service.getCompanyById(id, enqueueSnackbar).then((response) => {
      if (!response.error) {
        setCompanyData(response);
        setLoading(false);

        let PaymentStatus = checkCompanyPaymentStatus(response);  
        setStatus({
          PaymentStatus,
          JoiningDate: response["JoiningDate"],
          TrialsDays: response["TrialDays"],
          TrialEnds: moment(response["JoiningDate"])
            .add(response["TrialDays"], "days")
            .format("MM/DD/YYYY"),
        });
      }
    });
  }, [id, enqueueSnackbar]);

  const StripeCustomerID = companyData.StripeCustomerID;

  //billing api data
  useEffect(() => {
    if (StripeCustomerID) {
      Service.getInvoiceData(StripeCustomerID, enqueueSnackbar).then(
        (response) => {
          if (response) {
            setInvoiceData(response?.data);
            setLoading(false);
          }
        }
      );
    }
  }, [StripeCustomerID, enqueueSnackbar]);

  const CanoID = companyData?.id;

  const formattedResponse = invoiceData?.map((item) => {
    const timestamp = item.created;
    const date = new Date(timestamp * 1000);

    // Get year, month, and day
    const year = date.getFullYear();
    const monthIndex = date.getMonth(); // Month index from 0 to 11
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = monthNames[monthIndex];

    const day = String(date.getDate()).padStart(2, "0");

    // Form the date in the "YYYY-MM-DD" format
    const formattedDate = `${year}/${monthIndex + 1}/${day}`;

    // Return a new object with the formatted date and other properties
    return {
      ...item,
      created: formattedDate,
      month: monthIndex + 1,
      monthName,
      year,
    };
  });

  const monthWiseData = [];
  formattedResponse?.forEach((element) => {
    const newKey = element.monthName + " " + element.year;
    if (monthWiseData[newKey]) {
      monthWiseData[newKey].count = monthWiseData[newKey].count + 1;
      monthWiseData[newKey].amount =
        monthWiseData[newKey].amount + element.amount_received;

      monthWiseData[newKey].data.push(element);
    } else {
      monthWiseData[newKey] = {
        count: 1,
        date: newKey,
        amount: element.amount_received,
        data: [element],
      };
    }
  });
  const monthWiseDataObject = Object.values(monthWiseData).map((item) => ({
    date: item.date,
    count: item.count,
    amount: item.amount,
    data: item.data,
  }));

  const handleBillingRowClick = (selectedDate) => {
    if (selectedDate) {
      navigate("/company/:id/company-details/billing-details", {
        state: { billingData: selectedDate, CanoID: CanoID },
      });
    }
  };

  useEffect(() => {
    if (CanoID) {
      Service.getCanoUser(CanoID).then((response) => {
        if (!response.error) {
          setData(response);
          setLoading(false);
        }
      });
    }
  }, [CanoID, accessToken]);

  const handleButtonClick = () => {
    navigate("/company/company-details/add-user", {
      state: { companyData: companyData },
    });
  };

  const handleRowClick = (row) => {
    navigate(`/company/company-details/edit-user`, {
      state: { companyData: companyData, Email: row.email },
    });
  };

  useEffect(() => {
    // Filter Worker data based on the search input
    const filteredWorkerResult = data?.filter((dataItem) => {
      const fullName = `${dataItem.FName}`;
      return (
        fullName.toLowerCase().includes(search.toLowerCase()) &&
        (dataItem.UserType === "Tech" || dataItem.UserType === "Hybrid User")
      );
    });
    setFilteredWorkerData(filteredWorkerResult);

    // Filter Inventory Staff data based on the search input
    const filteredInventoryResult = data?.filter((dataItem) => {
      const fullName = `${dataItem.FName} ${dataItem.LName}`;
      return (
        fullName.toLowerCase().includes(search.toLowerCase()) &&
        (dataItem.UserType === "Inventory Staff" ||
          dataItem.UserType === "Hybrid User")
      );
    });

    setFilteredInventoryData(filteredInventoryResult);

    // Filter Hybrid User data based on the search input
    const filteredAdminResult = data?.filter((dataItem) => {
      const fullName = `${dataItem.FName} ${dataItem.LName}`;
      return (
        fullName.toLowerCase().includes(search.toLowerCase()) &&
        dataItem.AdminPanel === "Y"
      );
    });
    setFilteredHybridData(filteredAdminResult);

     // Filter Field data based on the search input
     const filteredFieldResult = data?.filter((dataItem) => {
      const fullName = `${dataItem.FName} ${dataItem.LName}`;
      return (
        fullName.toLowerCase().includes(search.toLowerCase()) &&
        (dataItem.UserType === "Field")
      );
    });
    setFilteredFieldData(filteredFieldResult);

  }, [search, data]);

  const JoiningDate = companyData?.JoiningDate;
  let formattedDate;

  if (JoiningDate) {
    formattedDate = moment(JoiningDate).format("YYYY-MM-DD "); // Adjust the format as needed
  } else {
    formattedDate = "N/A";
  }

  const columns = [
    {
      name: "Name",
      width: "400px",
      cell: (row) => (
        <div>
          <img src={boxIcon} alt="Profile" />
          <span className="mx-2">{row?.FName + " " + row?.LName || "-"}</span>
        </div>
      ),
    },

    {
      name: "Employee ID",
      selector: (row) => row?.EmployeeCode || "-",
      sortable: true,
    },
    {
      name: "Warehouse",
      selector: (row) => row?.DefaultWarehouse,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email || "-",
      sortable: true,
    },
    {
      name: "Contact no.",
      selector: (row) => row?.Phone || "-",
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div
          className="d-flex justify-content-space-between W-100"
          onClick={() => handleRowClick(row)}
        >
          <span style={{ cursor: "pointer" }}>
            <img src={editIcon} alt="edit" className="edit-icon" />
          </span>
        </div>
      ),
    },
  ];
  const fieldColumns = [
    {
      name: "Name",
      width: "400px",
      cell: (row) => (
        <div>
          <img src={boxIcon} alt="Profile" />
          <span className="mx-2">{row?.FName + " " + row?.LName || "-"}</span>
        </div>
      ),
    },

    {
      name: "Employee ID",
      selector: (row) => row?.EmployeeCode || "-",
      sortable: true,
    },
    {
      name: "Warehouse",
      selector: (row) => row?.DefaultWarehouse,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email || "-",
      sortable: true,
    },
    {
      name: "Contact no.",
      selector: (row) => row?.Phone || "-",
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div
          className="d-flex justify-content-space-between W-100"
          onClick={() => handleRowClick(row)}
        >
          <span style={{ cursor: "pointer" }}>
            <img src={editIcon} alt="edit" className="edit-icon" />
          </span>
        </div>
      ),
    },
  ];
  const workerColumns = [
    {
      name: "Name",
      width: "400px",
      cell: (row) => (
        <div>
          <img src={boxIcon} alt="Profile" />
          <span className="mx-2">{row?.FName + " " + row?.LName || "-"}</span>
        </div>
      ),
    },

    {
      name: "Employee ID",
      selector: (row) => row?.EmployeeCode || "-",
      sortable: true,
    },

    {
      name: "Email",
      selector: (row) => row?.email || "-",
      sortable: true,
      width: "200px",
    },
    {
      name: "Contact no.",
      selector: (row) => row?.Phone || "-",
      sortable: true,
      width: "150px",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div
          className="d-flex justify-content-space-between W-100"
          onClick={() => handleRowClick(row)}
        >
          <span style={{ cursor: "pointer" }}>
            <img src={editIcon} alt="edit" className="edit-icon" />
          </span>
        </div>
      ),
    },
  ];
  const adminColumns = [
    {
      name: "Name",
      width: "300px",
      cell: (row) => (
        <div>
          <img src={boxIcon} alt="Profile" />
          <span className="mx-2">{row?.FName + " " + row?.LName || "-"}</span>
        </div>
      ),
    },

    {
      name: "Employee ID",
      selector: (row) => row?.EmployeeCode || "-",
      sortable: true,
    },

    {
      name: "Email",
      selector: (row) => row?.email || "-",
      sortable: true,
      width: "200px",
    },
    {
      name: "Contact no.",
      selector: (row) => row?.Phone || "-",
      sortable: true,
      width: "150px",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div
          className="d-flex justify-content-space-between W-100"
          onClick={() => handleRowClick(row)}
        >
          <span style={{ cursor: "pointer" }}>
            <img src={editIcon} alt="edit" className="edit-icon" />
          </span>
        </div>
      ),
    },
  ];
  const BillingColumns = [
    {
      name: "Month",
      cell: (row) => (
        <div>
          <img src={invoice} alt="Profile" />
          <span className="mx-2">{row?.date || "-"}</span>
        </div>
      ),
    },

    {
      name: "Amount ($)",
      selector: (row) => {
        if (row && row?.amount !== undefined) {
          return row?.amount / 100;
        }
        return "";
      },
      sortable: true,
      width: "300px",
    },

    {
      name: "Count",
      selector: (row) => row?.count || "-",
      sortable: true,
    },
  ];

  return (
    <div className="home_content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-heading d-flex">
              <Link to="/company">
                <img src={BackArrow} alt="back arrow" />
              </Link>
              <h4>Cano Account Details</h4>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="custom-table">
              <div className="table-responsive">
                <Table className="Company-details">
                  <tbody className="tbody-custom">
                    <tr>
                      <td>
                        <h5>Cano ID</h5>
                        <p className="m-0">{companyData?.id || "-"}</p>
                      </td>
                      <td>
                        <h5>Company code</h5>
                        <p className="m-0">{companyData?.CompanyCode || "-"}</p>
                      </td>
                      <td>
                        <h5>Joining Date</h5>

                        <p className="m-0">{formattedDate || "-"}</p>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mt-2">
          <div className="col-lg-5">
            <nav>
              <div
                className="nav nav-tabs nav-fill"
                id="nav-tab"
                role="tablist"
              >
                <a
                  className="nav-item nav-link custom-tab active"
                  id="nav-home-tab"
                  data-toggle="tab"
                  href="#nav-home"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  Inventory staff
                </a>
                <a
                  className="nav-item nav-link custom-tab"
                  id="nav-profile-tab"
                  data-toggle="tab"
                  href="#nav-profile"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  Tech
                </a>
                <a
                  className="nav-item nav-link custom-tab"
                  id="nav-Hybrid-tab"
                  data-toggle="tab"
                  href="#nav-Hybrid"
                  role="tab"
                  aria-controls="nav-Hybrid"
                  aria-selected="false"
                >
                  Admin
                </a>
                <a
                  className="nav-item nav-link custom-tab"
                  id="nav-Field-tab"
                  data-toggle="tab"
                  href="#nav-Field"
                  role="tab"
                  aria-controls="nav-Field"
                  aria-selected="false"
                >
                  Field
                </a>
                <a
                  className="nav-item nav-link custom-tab"
                  id="nav-contact-tab"
                  data-toggle="tab"
                  href="#nav-contact"
                  role="tab"
                  aria-controls="nav-contact"
                  aria-selected="false"
                >
                  Billing Details
                </a>
              </div>
            </nav>
          </div>
        </div>
        <div className="tab-content mt-3" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <div className="row mt-5 mb-5">
              <div className="col-lg-12 ">
                <div className=" align-left company_details ">
                  {!loading && (
                    <div>
                      <div className="search_and_add_new my-3">
                        <div className="search-bar mx-5">
                          <Form>
                            <Form.Group style={{ position: "relative" }}>
                              <img src={SearchIcon} alt="Search" />
                              <Form.Control
                                type="text"
                                placeholder="Search by Name"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </Form.Group>
                          </Form>
                        </div>

                        <div className="btn-custom scan-item">
                          <button onClick={handleButtonClick}>
                            <img src={AddIcon} alt="add Icon" /> Add New
                          </button>
                        </div>
                      </div>
                      <DataTable
                        columns={columns}
                        data={
                          search
                            ? filteredInventoryData
                            : data.filter(
                                (item) =>
                                  item.UserType === "Inventory Staff" ||
                                  item.UserType === "Hybrid User"
                              )
                        }
                        pagination
                        paginationRowsPerPageOptions={[5, 10, 15]}
                        subHeader
                        subHeaderAlign="left"
                        progressPending={loading}
                        progressComponent={
                          <div className="d-flex justify-content-center align-items-center">
                            <Spinner
                              animation="border"
                              role="status"
                              style={{ color: "skyblue" }}
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          </div>
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
          >
            <div className="row mt-5 mb-5">
              <div className="col-lg-12 ">
                <div className=" align-left company_details ">
                  {!loading && (
                    <div>
                      <div className="search_and_add_new my-3">
                        <div className="search-bar mx-5">
                          <Form>
                            <Form.Group style={{ position: "relative" }}>
                              <img src={SearchIcon} alt="Search" />
                              <Form.Control
                                type="text"
                                placeholder="Search.."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </Form.Group>
                          </Form>
                        </div>

                        <div className="btn-custom scan-item">
                          <button onClick={handleButtonClick}>
                            <img src={AddIcon} alt="add Icon" /> Add New
                          </button>
                        </div>
                      </div>
                      <DataTable
                        columns={workerColumns}
                        data={
                          search
                            ? filteredWorkerData
                            : data.filter(
                                (item) =>
                                  item.UserType === "Tech" ||
                                  item.UserType === "Hybrid User"
                              )
                        }
                        pagination
                        paginationRowsPerPageOptions={[5, 10, 15]}
                        subHeader
                        subHeaderAlign="left"
                        progressPending={loading}
                        progressComponent={
                          <div className="d-flex justify-content-center align-items-center">
                            <Spinner
                              animation="border"
                              role="status"
                              style={{ color: "skyblue" }}
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          </div>
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="nav-Hybrid"
            role="tabpanel"
            aria-labelledby="nav-Hybrid-tab"
          >
            <div className="row mt-5 mb-5">
              <div className="col-lg-12 ">
                <div className=" align-left company_details ">
                  {!loading && (
                    <div>
                      <div className="search_and_add_new my-3">
                        <div className="search-bar mx-5">
                          <Form>
                            <Form.Group style={{ position: "relative" }}>
                              <img src={SearchIcon} alt="Search" />
                              <Form.Control
                                type="text"
                                placeholder="Search.."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </Form.Group>
                          </Form>
                        </div>

                        <div className="btn-custom scan-item">
                          <button onClick={handleButtonClick}>
                            <img src={AddIcon} alt="add Icon" /> Add New
                          </button>
                        </div>
                      </div>
                      <DataTable
                        columns={adminColumns}
                        data={
                          search
                            ? filteredHybridData
                            : data.filter((item) => item.AdminPanel === "Y")
                        }
                        pagination
                        paginationRowsPerPageOptions={[5, 10, 15]}
                        subHeader
                        subHeaderAlign="left"
                        progressPending={loading}
                        progressComponent={
                          <div className="d-flex justify-content-center align-items-center">
                            <Spinner
                              animation="border"
                              role="status"
                              style={{ color: "skyblue" }}
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          </div>
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="nav-Field"
            role="tabpanel"
            aria-labelledby="nav-Field-tab"
          >
            <div className="row mt-5 mb-5">
              <div className="col-lg-12 ">
                <div className=" align-left company_details ">
                  {!loading && (
                    <div>
                      <div className="search_and_add_new my-3">
                        <div className="search-bar mx-5">
                          <Form>
                            <Form.Group style={{ position: "relative" }}>
                              <img src={SearchIcon} alt="Search" />
                              <Form.Control
                                type="text"
                                placeholder="Search by Name"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </Form.Group>
                          </Form>
                        </div>

                        <div className="btn-custom scan-item">
                          <button onClick={handleButtonClick}>
                            <img src={AddIcon} alt="add Icon" /> Add New
                          </button>
                        </div>
                      </div>
                      <DataTable
                        columns={fieldColumns}
                        data={
                          search
                            ? filteredFieldData
                            : data.filter(
                                (item) =>
                                  item.UserType === "Field"
                              )
                        }
                        pagination
                        paginationRowsPerPageOptions={[5, 10, 15]}
                        subHeader
                        subHeaderAlign="left"
                        progressPending={loading}
                        progressComponent={
                          <div className="d-flex justify-content-center align-items-center">
                            <Spinner
                              animation="border"
                              role="status"
                              style={{ color: "skyblue" }}
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          </div>
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="nav-contact"
            role="tabpanel"
            aria-labelledby="nav-contact-tab"
          >
            <div className="row mt-4 ">
              <div className="col-lg-12 ">
                <div className="custom-table ">
                  <div className="table-responsive">
                    <table className="table Company-details">
                      <tbody className="tbody-custom">
                        <tr>
                          <td className="p-0 pl-3 pt-3 ">
                            <h4 className="px-3" style={{ fontWeight: "700" }}>
                              {status?.["PaymentStatus"]}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5>Start Date</h5>
                            <p className="m-0">{status?.JoiningDate}</p>
                          </td>
                          <td>
                            {status?.["PaymentStatus"] === "Trial" ? (
                              <div>
                                <h5>End Date</h5>
                                <p className="m-0">{status?.["TrialEnds"]}</p>
                              </div>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            <h5>Status</h5>
                            <p
                              className="m-0  trial_status"
                              style={{
                                backgroundColor:
                                  status?.["PaymentStatus"] === "Active"
                                    ? "green "
                                    : status?.["PaymentStatus"] === "Inactive"
                                    ? "red"
                                    : status?.["PaymentStatus"] === "Trial"
                                    ? "#1477F8 "
                                    : "#000",
                              }}
                            >
                              {status?.["PaymentStatus"]}
                            </p>
                          </td>

                          <td className="trial-img">
                            <img
                              src={TrialImg}
                              className="trial-inner"
                              style={{ width: "70px" }}
                              alt="trial img"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-sm-4 mt-xs-0 mb-5">
              <div className="col-lg-12">
                <h5>Billing Details</h5>
              </div>
              <div className="col-lg-12 ">
                <div className=" align-left company_details ">
                  {!loading && (
                    <DataTable
                      columns={BillingColumns}
                      data={monthWiseDataObject}
                      onRowClicked={(row) => handleBillingRowClick(row)}
                      pagination
                      paginationRowsPerPageOptions={[5, 10, 15]}
                      subHeader
                      pointerOnHover
                      subHeaderAlign="left"
                      progressPending={loading}
                      progressComponent={
                        <div className="d-flex justify-content-center align-items-center">
                          <Spinner
                            animation="border"
                            role="status"
                            style={{ color: "skyblue" }}
                          >
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </div>
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompanyDetails;
