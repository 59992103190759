import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import AddIcon from "../../assets/images/add-white.svg";
import SearchIcon from "../../assets/images/Search.svg";
import companyIcon from "../../assets/images/company-table.svg";
import Service from "../../shared/_services/inventory.service";
import DataTable from "react-data-table-component";
import editIcon from "../../assets/images/edit.svg";
// import UserContext from "../../context/userContext";
import { useSnackbar } from "notistack";
import "react-toggle/style.css";
import ReactToggle from "react-toggle";
const Company = () => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [canoAccount, setCanoAccount] = useState([]);

  // const user = useContext(UserContext);
  // const accessToken = user?.id;

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  function escapeRegExp(input) {
    return input.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }
  const handleRowClick = (row) => {
    // Redirect to the details page for the selected row
    navigate(`/company/${row.id}/company-details`);
  };

  function daysInThisMonth() {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
  }

  var getRemanningDays = function () {
    var date = new Date();
    var time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);
    return time.getDate() > date.getDate()
      ? time.getDate() - date.getDate()
      : 0;
  };

  const handleStatusChange = async (companyId, newStatus, row) => {
    let statusMessage;
    try {
      const currentDate = new Date();
      const paymentDate = new Date(row?.StripePaymentStatus?.split(" - ")[1]);

      if (
        paymentDate.getMonth() !== currentDate.getMonth() ||
        paymentDate.getFullYear() !== currentDate.getFullYear()
      ) {
        if (
          moment(row["JoiningDate"]).add(row["TrialDays"], "days").format() <
          moment().format()
        ) {
          if (newStatus === false) {
            if (row.StripeCustomerID === "" || row.StripeCustomerID === null) {
              statusMessage = "ERROR: Missing CustomerID and PaymentMethodID ";
            } else if (
              row.StripePaymentMethodID === "" ||
              row.StripePaymentMethodID === null
            ) {
              statusMessage = "ERROR: Missing PaymentMethodID ";
            } else {
              let UsersCounts = {};
              let { data } = await axios.get(
                `https://celtic.azurewebsites.net/api/APIv1GetCanoUsersForCanoAccount?CanoID=${row.id}`
              );

              if (data.length > 0) {
                for (let i of data) {
                  UsersCounts[i["UserType"]] =
                    (UsersCounts[i["UserType"]] || 0) + 1;
                }

                let UsersPrice = {
                  "Hybrid User": row["PricePerHybridUser"],
                  Tech: row["PricePerWorker"],
                  "Inventory Staff": row["PricePerInventoryStaff"],
                  "Field": row["PricePerField"],
                };

                let TotalUsersPrice = 0;
                for (const userType in UsersPrice) {
                  const userCount = UsersCounts[userType] || 0;
                  const pricePerUser = UsersPrice[userType] || 0;
                  TotalUsersPrice += userCount * pricePerUser;
                }
                try {
                  const saveIntent = await axios.post(
                    `https://celtic.azurewebsites.net/api/APIv1SaveStripePaymentIntentForUser`,
                    {
                      Price: Math.round(
                        (parseInt(TotalUsersPrice) / daysInThisMonth()) *
                        getRemanningDays()
                      ),
                      Description: `Monthly payment intent for all active employees of ${row.id}`,
                      CustomerID: row.StripeCustomerID,
                      PaymentMethodID: row.StripePaymentMethodID,
                    }
                  );

                  if (saveIntent.data.status === "success") {
                    statusMessage =
                      saveIntent.data.message.id +
                      " - " +
                      moment().format("YYYY-MM-DD");
                  } else {
                    statusMessage = "Error :" + saveIntent.data.message;
                  }
                } catch (error) {
                  statusMessage = "Error :" + error.data.message;
                }
              }
            }
          }
        } else {
          statusMessage = "ERROR: Trial period, no payment.";
        }
      }
    } catch (error) {
      console.log("Not valid date", row.StripePaymentStatus);
      statusMessage = "Error :" + error.message;
    }
    console.log(statusMessage);
    axios
      .post(`https://celtic.azurewebsites.net/api/APIv1SaveCanoAccount`, {
        ...row,
        StripePaymentStatus: statusMessage || row.StripePaymentStatus,
        RestrictedCompanies: row.RestrictedCompanies || [],
        Disabled: newStatus,
      })
      .then((response) => {
        if (response.data.status === "success" || response.status == "200") { // eslint-disable-line
          setCompanyData((prevCompanies) =>
            prevCompanies.map((company) =>
              company.id === companyId
                ? { ...company, Disabled: newStatus }
                : company
            )
          );
          enqueueSnackbar("Status updated successfully.", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Failed to update status.", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        enqueueSnackbar("An error occurred while updating the status.", {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    Service.getAllCanoAccount().then((response) => {
      if (!response?.error) {
        setCanoAccount(response);
        setLoading(false);
      } else {
        enqueueSnackbar(response.message, {
          variant: "error",
        });
      }
    });
  }, [enqueueSnackbar]);

  //getting all cano account data by cano id
  useEffect(() => {
    setLoading(true);

    if (canoAccount?.length > 0) {
      let sampleCanoData = {};
      for (let acc of canoAccount) {
        sampleCanoData[acc.id] = {
          InventoryStaff: acc["Inventory Staff"] || 0,
          HybridUser: acc["Hybrid User"] || 0,
          Tech: acc["Tech"] || 0,
          Field: acc["Field"] || 0,
        };
      }
      const promises = canoAccount.map((account) =>
        Service.getCanoAccountByCanoId(account.id, enqueueSnackbar)
      );
      Promise.all(promises)
        .then((responses) => {
          const validResponses = responses.filter((res) => res !== undefined);
          for (let valid of validResponses) {
            valid.data["counts"] = sampleCanoData[valid.data.id];
          }
          setCompanyData(validResponses.map((res) => res.data));
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching company data by CANO ID:", error);
          setLoading(false);
        });
    }
  }, [enqueueSnackbar, canoAccount]);

  useEffect(() => {
    if (companyData) {
      const escapedSearch = escapeRegExp(search);
      const resultData = companyData?.filter((company) => {
        return company?.CompanyName?.toLowerCase().match(
          new RegExp(escapedSearch, "i")
        );
      });

      setFilteredData(resultData);
    }
  }, [search, companyData]);

  const columns = [
    {
      name: "Name",

      selector: (row) => (
        <div className="custom-cell">
          <img src={companyIcon} alt="Profile" />
          <span className="mx-2">{row?.CompanyName}</span>
        </div>
      ),
      width: "400px",
    },

    {
      name: "CanoID",
      selector: (row) => row.id || "-",
      sortable: true,
    },
    {
      name: "Company Code",
      selector: (row) => row?.CompanyCode || "-",
      sortable: true,
    },

    {
      name: "Inventory staff",
      selector: (row) => row?.counts.InventoryStaff || "-",
      sortable: true,
    },
    {
      name: "Tech",
      selector: (row) => row?.counts.Tech || "-",
      sortable: true,
    },
    {
      name: "Hybrid User",
      selector: (row) => row?.counts.HybridUser || "-",
      sortable: true,
    },
    {
      name: "Field",
      selector: (row) => row?.counts.Field || "-",
      sortable: true,
    },
    {
      name: "Joining Date",
      width: "200px",
      selector: (row) => moment(row?.JoiningDate).format("YYYY-MM-DD "),
      sortable: true,
    },

    // {
    //   name: "Invoice",
    //   selector: (row) => row.currentInvoice,
    //   sortable: true,

    //   cell: (row) => (
    //     <div
    //       className="custom-cell"
    //       style={{
    //         background: InvoiceStatusColor.getColor(row.currentInvoice),
    //         padding: "5px 5px",
    //         width: "70%",
    //         borderRadius: "4px",
    //         textAlign: "center",
    //         color: "#fff",
    //       }}
    //     >
    //       {row.currentInvoice}
    //     </div>
    //   ),
    // },

    {
      name: "Status",
      cell: (row) => (
        <ReactToggle
          checked={!row.Disabled}
          onChange={(e) => handleStatusChange(row.id, !e.target.checked, row)}
        />
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex justify-content-space-between W-100">
          <Link to={`/company/${row?.id}/edit-company`}>
            <span style={{ cursor: "pointer" }}>
              <img src={editIcon} alt="edit" className="edit-icon" />
            </span>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className="container-fluid align-left inventory-count warehouse">
      <div className="page-heading">
        <h4 className="align-left top_head">Cano Accounts</h4>
      </div>

      <DataTable
        columns={columns}
        data={search ? filteredData : companyData}
        pagination
        paginationRowsPerPageOptions={[5, 10, 15]}
        onRowClicked={(row) => handleRowClick(row)}
        subHeader
        pointerOnHover
        subHeaderComponent={
          <div className=" add_and_search search_head">
            <div className="input-group  w-50">
              <img src={SearchIcon} className="search-icon" alt="search icon" />

              <input
                type="text"
                placeholder="Search by Name"
                className="form-control w-25 "
                style={{ borderRadius: "40px", paddingLeft: "40px" }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div>
              <div className="btn-custom scan-item">
                <Link to="/company/add-new-company">
                  <button>
                    <img src={AddIcon} alt="add Icon" /> Add New
                  </button>
                </Link>
              </div>
            </div>
          </div>
        }
        subHeaderAlign="left"
        progressPending={loading}
        progressComponent={
          <div className="d-flex justify-content-center align-items-center">
            <Spinner
              animation="border"
              role="status"
              style={{ color: "skyblue" }}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        }
      />
    </div>
  );
};

export default Company;
